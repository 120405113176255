import "./App.css";
import Modal from "./Modal.jsx";

function App() {
  return (
    <>
      <div className="App">
        <h1>We are currenty under maintance</h1>
      </div>
      <Modal />
    </>
  );
}

export default App;
